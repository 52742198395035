import React from 'react';

import Layout from '../components/layout/layout';
import { Headline } from '../components/layout/typography';
import Subscribe from '../components/sections/subscribe';
import Blog from '../components/sections/blog';

export default function SubscribePage() {
  return (
    <Layout
      title="Subscribe"
      description="Get honest product validation insights"
    >
      <div className="bg-white pt-40 px-4 sm:px-6 lg:pt-48 lg:px-8">
        <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <Headline className="text-7xl font-extrabold sm:text-9xl">
              Subscribe
            </Headline>
          </div>
        </div>
      </div>
      <Subscribe />
      <Blog />
    </Layout>
  );
}
